<template>
  <!-- <div>视频上传列表</div> -->
  <el-container class="VideoUploadList">
    <el-header height="auto">
      <div class="filter-pane reportTree">
        <label>车组车辆</label>
        <SelectGroupTree @on-select="onSelect"
          @on-vehicle="onConfirm"
          :multipleProp="{
            noGroup: true, // 是否可选车组
            groupCheckBox: false, //车组多选
            vehicleCheckBox: false, //车辆多选
          }"
          @dbClick="dbConfirm"
          class="TreeWidth"
          @clear="ClearSearchParams"></SelectGroupTree>
        <label>选择时间</label>
        <reportDate size="small"
          @change="onChoiceDate"></reportDate>
        <el-button style="font-size: 14px;margin-right: 15px;height:32px;width:90px;margin-left:20px;"
          type="primary"
          icon="ios-search"
          @click="searchOperRecord(1)">搜索</el-button>
      </div>
    </el-header>
    <el-main class="reportTable">
      <el-table size="mini"
        stripe
        border
        :data="data"
        v-loading="tableLoading"
        height="calc(100% - 45px)">
        <el-table-column type="index"
          label="序号"
          width="50px"
          align="center"></el-table-column>
        <el-table-column prop="plate"
          label="设备名称"
          min-width="80px"
          align="center"></el-table-column>
        <el-table-column prop="groupName"
          label="所属车组"
          min-width="100px"
          align="center"></el-table-column>
        <el-table-column prop="terminalNo"
          label="终端号"
          min-width="100px"
          align="center"></el-table-column>
        <el-table-column prop="opertionUser"
          label="操作用户"
          min-width="100px"
          align="center"></el-table-column>
        <el-table-column prop="alarmEndTime"
          label="操作时间"
          min-width="150px"
          align="center"></el-table-column>
        <el-table-column prop="upStartTime"
          label="文件开始时间"
          min-width="150px"
          align="center"></el-table-column>
        <el-table-column prop="upEndTime"
          label="文件结束时间"
          min-width="150px"
          align="center"></el-table-column>
        <el-table-column label="摄像头"
          min-width="60px"
          align="center">
          <template slot-scope="scope">
            <span>通道 {{scope.row.channel}}</span>
          </template>
        </el-table-column>
        <el-table-column label="上传状态"
          min-width="75px"
          align="center">
          <template slot-scope="scope">
            <span v-if="scope.row.result==0">成功</span>
            <span v-if="scope.row.result==1">失败</span>
            <span v-if="scope.row.result==2">正在上传</span>
          </template>
        </el-table-column>
        <el-table-column label="操作"
          width="75px">
          <template slot-scope="scope">
            <el-button type="text"
              :disabled="scope.row.result != 0"
              @click="onPlayClick(scope.row)">播放</el-button>
            <br />
            <el-button type="text"
              :disabled="scope.row.result != 0"
              @click="onDownloadClick(scope.row)">下载至本地</el-button>
          </template>
        </el-table-column>
      </el-table>
      <!-- 分页 -->
      <el-pagination background
        :current-page="tablePage.pageNumber"
        :page-size="tablePage.pageSize"
        layout="total, prev, pager, next, jumper"
        :total="tablePage.total"
        @current-change="changePage"></el-pagination>
    </el-main>
    <el-dialog title="在线播放"
      :visible.sync="dialogVisible"
      width="600px"
      class="reportDialog">
      <div>
        <video :src="vedioUrl"
          v-if="dialogVisible"
          autoplay="autoplay"
          width="100%"
          controls></video>
      </div>
      <span slot="footer"
        class="dialog-footer">
        <el-button @click="dialogVisible = false">取 消</el-button>
      </span>
    </el-dialog>
  </el-container>
</template>

<script>
//选择时间
import reportDate from "@/components/Date/reportDate";
//选择车组
import SelectGroupTree from '@/components/GroupTree/SelectGroupTree';
//搜索接口
import { getDownloadInfo } from '@/api/All-Interface.js';
export default {
  // `name`与路由保持一致
  name: 'VideoUploadList',
  data() {
    return {
      searchParams: {
        vehicleId: 0,
        groupId: 0,
        flag: 0,
        accState: '0',
        upStartTime: '',
        upEndTime: '',
        isToday: 0,// isToday 为 0 是 代表是‘今天 ’
      },
      data: [],//数据集合
      tableLoading: false,//用于加载数据时的等待状态
      tablePage: {
        //分页
        pageNumber: 1,
        pageSize: 20,
        total: 0
      },
      dialogVisible: false,
      vedioUrl: ""
    };
  },
  components: {
    reportDate,
    SelectGroupTree,
  },
  methods: {
    onChoiceDate(dates) {//选择时间
      this.searchParams.upStartTime = '';
      this.searchParams.upEndTime = '';
      if (dates.length === 0) return this.$message.warning('请选择正确的时间');
      if (dates.length === 2) {
        this.searchParams.upStartTime = dates[0];
        this.searchParams.upEndTime = dates[1];
      }
    },
    //车组车辆搜索框选择
    onSelect(node) {
      this.searchParams.vehicleId = node.vehicleId || 0;
      this.searchParams.groupId = node.groupId || 0;
      if (node.type == 2) {
        this.searchParams.flag = 1;
      } else if (node.type == 1) {
        this.searchParams.flag = 0;
      }
    },
    // 选择车辆事件
    onConfirm(node) {
      //先进行清空
      this.searchParams.vehicleId = 0;
      this.searchParams.groupId = 0;

      this.searchParams.vehicleId = node.vehicleId || 0;
      this.searchParams.flag = 1;
      this.searchParams.groupId = node.groupId || 0;
    },
    // 选择车组
    dbConfirm(node) {
      this.searchParams.pageNumber = 1;//重新选择车组车辆时间时，页数重置
      this.searchParams.flag = 0;
      this.searchParams.groupId = node.groupId;
      this.searchParams.vehicleId = 0;
    },
    //点击清除时，初始化车组车辆参数
    ClearSearchParams() {
      this.searchParams.vehicleId = 0;
      this.searchParams.groupId = 0;
    },
    //分页改变页数
    changePage(current) {
      this.tablePage.pageNumber = current;
      this.searchOperRecord(current);
    },
    //验证是否有输入车组或车辆
    verification() {
      if (this.searchParams.groupId == "" || this.searchParams.groupId == 0) {
        this.$message.info("请输入或者选择车组车辆");
        return false;
      } else {
        return true;
      };
    },
    //点击搜索
    searchOperRecord(current) {
      if (!this.verification()) return;//验证是否有输入车组或车辆
      this.tableLoading = true;//用于加载数据 未完成时 的等待状态
      getDownloadInfo({
        ...this.searchParams,
        pageSize: this.tablePage.pageSize,
        pageNumber: this.tablePage.pageNumber,
        _qt: new Date().getTime()
      }).then((res) => {
        this.data = res.rows;
        if (res.flag == 1) {
          this.tablePage.total = res.total;
          this.data = res.rows;
          if (this.data.length <= 0) {
            this.$message.info("请求数据为空！");
          }
        } else {
          this.data = [];
          this.$message.error(res.msg);
        }
        this.tableLoading = false;//加载数据完成，则将等待状态解除
      });
    },
    onPlayClick(row) { //打开查看图片的框框
      this.dialogVisible = true;
      this.vedioUrl = row.url;
    },
    onDownloadClick(row) {
      const i = row.filePath.lastIndexOf("/");
      const name = row.filePath.substr(i + 1);
      if (name) {
        const a = document.createElement("a");
        a.target = "_blank";
        a.href = row.url;
        a.download = name;
        a.click();
      }
    },
  }
}
</script>
<style lang="scss" scoped>
@import 'src/style/reportStyle.scss';
@import 'src/style/reportTreeAndData.scss';
.VideoUploadList {
  height: 100%;
  .el-header {
    margin: 0;
    padding: 0;
  }
  .el-main {
    height: calc(100% - 48px) !important;
    padding: 10px 20px 0 20px;

    .el-pagination {
      padding: 12px 5px 5px;
      text-align: center;
    }
  }
  .TreeWidth {
    ::v-deep .inline-input {
      width: 220px;
    }
  }
  .reportDialog {
    ::v-deep .el-dialog__body {
      padding: 30px 30px 50px 30px;
    }
  }
}
</style>